<template>
  <div>
    <div class="text-center">
        <b-img :src="require('@/assets/images/cosechando_txt.png')" fluid class="title-img"></b-img>
    </div>      
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>