import Vue from 'vue'
import VueRouter from 'vue-router'
import VueBodyClass from 'vue-body-class';

import Home from '@/views/Home.vue'
import PreviewGame from "@/views/PreviewGame.vue"
import Play from "@/views/Play.vue";
import TransaccionesList from "@/views/TransaccionesList.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { bodyClass: 'home' },
  },
  {
    path: '/preview_game',
    name: 'PreviewGame',
    component: PreviewGame,
    meta: { bodyClass: 'config' },
  },
  {
    path: '/play/:count',
    name: 'play',
    component: Play,
    meta: { bodyClass: 'play' },
  },
  {
    path: '/transacciones',
    name: 'TransaccionesList',
    component: TransaccionesList,
    meta: { bodyClass: 'transacciones' },
  },    
]

const vueBodyClass = new VueBodyClass(routes);


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) });


export default router
