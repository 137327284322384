<template>
  <div>

      <art />

      <!-- seleccion de cubos de hielo -->      

      <div class="over" style="position: relative !important;">
          <cosechando-momentos />
          <b-row>
              <b-col auto></b-col>
              <b-col cols="8" sm="10" md="7">   
                <div class="title mx-auto text-center mb-4">
                  <b-img :src="require('@/assets/images/txt cuantos hielos poner.png')" fluid class="d-block mx-auto"></b-img>
                </div> 
                <b-row>
                  <b-col cols="12" sm="4" md="4">
                    <div class="w-85 p-4 py-5 btn-icecube mx-auto my-2" @click="actionPlay(1)">                      
                        <b-img :src="require('@/assets/images/enVaso1hielo.png')" center class="img-icecube" />
                        <div class="count mt-4">
                          <h1 class="fw-bold">1</h1>
                        </div>
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4">
                    <div class="w-85 p-4 py-5 btn-icecube mx-auto my-2" @click="actionPlay(2)">                      
                        <b-img :src="require('@/assets/images/enVaso2hielos.png')" center class="img-icecube" style="width: 5.3rem" />
                        <div class="count mt-4">
                          <h1 class="fw-bold">2</h1>
                        </div>
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4">
                    <div class="w-85 p-4 py-5 btn-icecube mx-auto my-2" @click="actionPlay(3)">                      
                        <b-img :src="require('@/assets/images/enVaso3hielos.png')" center class="img-icecube" />
                        <div class="count mt-4">
                          <h1 class="fw-bold">3</h1>
                        </div>
                    </div>
                  </b-col>
                </b-row>                   
              </b-col>
              <b-col auto></b-col>
          </b-row>
      </div>         
      <transacciones />    
  </div>
</template>

<script>
import Art from '../components/Art.vue';
import CosechandoMomentos from '../components/CosechandoMomentos.vue';
import Transacciones from '../components/Transacciones.vue';
export default {
  components: { Art, CosechandoMomentos, Transacciones },
  methods : {
    actionPlay ( count ) {
      return this.$router.push({ name : 'play', params : { count }});
    }
  }
}
</script>
<style scoped>
 .btn-icecube {
   border-radius: 2em;
   border: 1px solid #eed29b;
   color : #927b3f;
   text-align: center;
 }
 .btn-icecube:hover {
   background-color: #eed29b !important;
   cursor: pointer;
 }
 .img-icecube {
   width: 4rem;
   height: 4rem;
 }
 .w-85 {
   width: 90% !important;
 }
 .title {
   width: 60%;
 }
</style>
<style scoped>
  .config .fig-middle-left {
    width: 15%;
    left: 0;
    z-index: 1;
  }
</style>