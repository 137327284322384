<template>
  <div>
      <div id="loading-msg1">
          <div class="loading-text text-center mx-auto my-auto text-white">
              <h1 class="fw-light">Aguarde...   </h1>
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    #loading-msg1 {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #ea8215;
        opacity: 0.8;
        width: 100vw;
        height: 100vh;
        z-index: 999999;
    }
    .loading-text {
        position: fixed;
        height: 150px;
        margin: auto;
        bottom: 0;
        top : 0;
        left: 0;
        right: 0;
    }
</style>