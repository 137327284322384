import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    countForms : 0
  },
  mutations: {
    totalRows(state, payload) {
      return (typeof localStorage.store !== 'undefined') ? JSON.parse(localStorage.store).length : 0;
    }
  },
  actions : {
    totalRows ({commit}) {
      return (typeof localStorage.store !== 'undefined') ? JSON.parse(localStorage.store).length : 0;
    }
  },
  modules: {
  }
})
