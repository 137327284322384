<template>
  <div>
        <div id="loading-msg2">
        </div>
        <div class="loading-text text-center bg-orange">
            <h5 class="fw-light">¡MALA SUERTE!</h5>
            <br>
            <h1 class="text-danger">¡PERDISTE!</h1>
            <br>
            <h4 class="fw-light">
                No llegaste a detener la carga a tiempo
            </h4>
            <br>
            <p>
                <b-button variant="light" class="w-75 mx-auto" @click="actionHome()">CERRAR</b-button>
            </p>
        </div>      
  </div>
</template>

<script>
export default {
    methods : {
        actionHome () {
            return this.$router.push({name : 'home'})
        }
    }
}
</script>

<style>
    #loading-msg2 {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #da909a;
        opacity: 0.8;
        width: 100vw;
        height: 100vh;
        z-index: 999999;
    }
    .loading-text {
        position: fixed;
        width: 75%;
        height: 45vh;
        margin: auto;
        bottom: 0;
        top : 0;
        left: 0;
        right: 0;
        z-index: 99999999;
        border-radius: 3em;
        padding: 3em;
    }
    .bg-orange {
        background-color: #ea8215;
    }
</style>