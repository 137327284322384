<template>
  <div>
      <art />
      <div class="over">
        <b-row>
          <b-col cols="12">
            <br>
            <div id="vaso-content">
              <cosechando-momentos />
              <div id="vaso" class="mx-auto" 
                    @touchstart="runFill(true)" 
                    @touchmove="runFill(false)" 
                    @touchcancel="runFill(false)"
                    @touchend="runFill(false)"
              >
                <div class="texto-indicaciones">
                  <b-img :src="require('@/assets/images/texto Indicaciones.png')" fluid style="width: 100%"></b-img>
                </div>
                <div class="area-win"></div>
                <div class="icecube-choose">
                  <b-img v-if="totalIceCubes == 1" :src="require('@/assets/images/enVaso1hielo.png')" style="width: 100%" />
                  <b-img v-if="totalIceCubes == 2" :src="require('@/assets/images/enVaso2hielos.png')" style="width: 100%" />
                  <b-img v-if="totalIceCubes == 3" :src="require('@/assets/images/enVaso3hielos.png')" style="width: 100%" />
                </div>
                <span class="wine" id="wine"></span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <transacciones />    

      <play-win v-if="ganaste" />
      <play-loose v-if="perdiste" />

  </div>
</template>

<script>
import Art from '@/components/Art.vue'
import CosechandoMomentos from '@/components/CosechandoMomentos.vue'
import Transacciones from '../components/Transacciones.vue';
import PlayWin from '../components/PlayWin.vue';
import PlayLoose from '../components/PlayLoose.vue';

export default {
  components: { Art, CosechandoMomentos, Transacciones, PlayWin, PlayLoose },
  data () {
    return {
      ganaste : false,
      perdiste : false
    }
  },
  computed : {
    totalIceCubes () {
      return this.$route.params.count;
    }
  },
  methods : {

    runFill(fill = false) {

      var element = document.getElementById('vaso');
      let wine = document.getElementById('wine');

      let glass_height = parseFloat(element.offsetHeight);
      let wine_height = parseFloat(wine.offsetHeight);

      let final_perc = (wine_height * 100) / glass_height;


      if (fill == true) {
        element.classList.add('touched');
        wine.style.animationPlayState = 'running';
      } else {
        element.classList.add('pause');
        wine.style.animationPlayState = 'paused';
        
        //alert(final_perc);

        if (final_perc >= 77 && final_perc <= 89) 
        {
          
          this.perdiste = false;
          
          setTimeout(() => {
            this.ganaste = true;
          }, 750);

        } else {
          
          this.ganaste = false;
          
          setTimeout(() => {
            this.perdiste = true;          
          }, 750);

        }
      }

    } 

  }
}
</script>

<style>
  .texto-indicaciones {
    position: absolute;
    width: 75%;
    top: 25%;
    left: -90%;
  }
  #vaso-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 45%;
  }
  #vaso {
    position: relative;
    left : 0;
    right: 0;
    margin: auto; 
    width: 32%;
    height: 55vh;
    border: 1em solid #e6e6e6;    
    /*border-radius: 25% 25% 40% 40% / 60% 60% 45% 45%;*/
    border-radius: 0% 0% 50% 50%;
    z-index: 99999;
  }
  .area-win {
    position: absolute;
    top: 3%;
    left: -20%;
    width: 140%;
    height: 15%;
    border: 3px dashed #ea8215;
    z-index: 99998;
  }
  .icecube-choose {
    position: absolute;
    margin: auto;
    bottom: 10%;
    left: 0;
    right: 0;
    width: 65%;
    text-align: center;
    z-index: 99997;
  }
  .wine {
    position: absolute;
    display: block;
    margin: auto;
    bottom: 0;
    left: 0; right: 0;
    background: url('~@/assets/images/bg-vaso.png') repeat;
    width: 100%;     
    z-index: 99996;
  }

  #vaso.touched .wine {
    height: 100%;   
    animation: cargaVaso 750ms linear forwards;
  }

  #vaso.touched.paused .wine {
    -webkit-animation-play-state:paused !important;
    -moz-animation-play-state:paused !important;
    -o-animation-play-state:paused !important; 
    animation-play-state:paused !important;
  }

  .play .fig-middle-left {
    display: none !important; 
  }
  .play .fig-bottom-center {
      position: fixed;
      margin: 0;
      left : 2rem;
      bottom: 4rem;
      width: 16vw;
  }
  @keyframes cargaVaso {
    0% {      
      width: 80%;
      height: 5%;
      border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
    }
    15% 
    {
      width: 80%;
      height: 15%;
      border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;      
    }
    30% {
      width: 92%;
      height: 30%;
      border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
    }    
    50% {
      width: 100%;
      height: 50%;
      border-radius: 0% 0% 50% 50% / 0% 0% 100% 100%;
    } 
    65% {
      width: 100%;
      height: 70%;
      border-radius: 0% 0% 75% 75% / 0% 0% 100% 100%;
    } 
    
    70% {
      width: 100%;
      height: 75%;
      border-radius: 0% 0% 75% 75% / 0% 0% 100% 100%;
    }
    80% {
      width: 100%;
      height: 85%;
      border-radius: 0% 0% 85% 85% / 0% 0% 100% 100%;
    }  
    90% {
      width: 100%;
      height: 95%;
      border-radius: 0% 0% 95% 95% / 0% 0% 100% 100%;
    }            
    100% {
      width: 100%;
      height: 99%;
      border-radius: 0% 0% 100% 100% / 0% 0% 100% 100%;
    }

  }
</style>