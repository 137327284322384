<template>
  <div>
      <b-container>
            <b-button class="my-4" :to="{name : 'home' }" exact >Volver al juego</b-button>
            <b-button variant="success" class="my-4 mx-2" @click="enviarPendientes">Enviar pendientes</b-button>
            <br>
            <table class="table table-stripped table-bordered" v-if="items.length > 0">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Telefono</th>
                        <th>Género</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) of items" :key="i" >
                        <td>{{ item.nombre }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.telefono }}</td>
                        <td>{{ genero[item.genero] }}</td>
                    </tr>
                </tbody>
            </table>
      </b-container>
      <loading v-if="loading == true" />
  </div>
</template>

<script>
import axios from 'axios';
import Loading from '../components/Loading.vue';

export default {
    components : {
        Loading
    },    
    data () {
        return {
            loading : false,
            genero : {
                null : 'Género',
                1 : 'Femenino',
                2 : 'Masculino',
                3 : 'Neutro / No binario'
            },
            items : JSON.parse(localStorage.store)
        }
    },
    methods : {
        enviarPendientes () {

            let t  = this;
            let { items } = this;

            this.loading = true;

            for (let i = 0; i < items.length; i++) {
                
                const persona = items[i];

                var subi = i - 1;
                
                console.log("persona a guardar >>> ", persona);

                axios.post('/insertar.php', persona, {
                    headers : {
                        "Access-Control-Allow-Origin" : "*",
                        'Content-Type' : 'multipart/form-data'
                    },                     
                })
                .then(data => {
                    console.log("error? " , data.data.error);
                    if (data.data.error == false) {

                        items.splice(0, i);
                    }
                }).then(() => {
                    t.loading = false;    
                    localStorage.store = JSON.stringify(items);
                }); 
                
            }

        }
    }
}
</script>

<style>

</style>