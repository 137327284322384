<template>
  <div id="app">
    <b-container fluid>
      <router-view />      
    </b-container>
  </div>
</template>
<script>
import Transacciones from './components/Transacciones.vue'
export default {
  components: { Transacciones },
}
</script>
<style>
    *:not(.form-control) {
      user-select: none !important;
    }
    .bg-pending {
      background: #ea8215 !important;
    }
    body {
        background-color: #f5f3f1 !important;
    }
    .over {
        position: relative !important;
        z-index: 9999;
    }
</style>
