<template>
  <div>
      <art />
      <div class="over">
        <cosechando-momentos />
        <b-container>
            <b-row>
                <b-col cols="2" sm="2" md="3">
                    
                </b-col>
                <b-col cols="9" sm="9" md="7" class="bg-white form-content" style="position: relative !important;">
                    <b-img :src="require('@/assets/images/botella.png')" class="f_botella" fluid></b-img>
                    <b-form id="form" class="ms-auto" @submit.prevent="runGame">
                        <b-form-group class="mb-3">
                            <b-form-input required autocomplete="off" v-model="datos.nombre" placeholder="Nombre"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-3">
                            <b-form-input type="email" required autocomplete="off" v-model="datos.email" placeholder="Email"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-3">
                            <b-form-input required autocomplete="off" v-model="datos.telefono" placeholder="Teléfono"></b-form-input>
                        </b-form-group>
                        <b-form-group class="mb-4">
                            <b-form-select class="form-control" required autocomplete="off" v-model="datos.genero" :options="generoCollection"></b-form-select>
                        </b-form-group>   
                        <b-form-group class="mb-4 text-center">
                            <b-form-checkbox v-model="datos.tyc" :value="true" :unchecked-value="false">
                                &nbsp;Acepta <span class="terms">términos</span> de privacidad.
                            </b-form-checkbox>
                        </b-form-group>        
                        <div class="">
                            <b-button variant="danger" class="w-100 btn-play" type="submit">Jugar</b-button>
                        </div>                                                       
                    </b-form>
                </b-col>
                <b-col auto></b-col>
            </b-row>
        </b-container>
      </div>
    <transacciones />    
    <loading v-if="loading"/>
  </div>
</template>

<script>
import Art  from "@/components/Art.vue";
import CosechandoMomentos from '../components/CosechandoMomentos.vue';
import Transacciones from '../components/Transacciones.vue';
import axios from "axios";
import Loading from '../components/Loading.vue';

export default {
    components : {
        Art, CosechandoMomentos,
        Transacciones,
        Loading, 
    },
    data () {
        return {
            loading : false,
            generoCollection : [
                { value : null, text : "Género" },
                { value : 1, text : "Femenino" },
                { value : 2, text : "Masculino" },
                { value : 3, text : "Neutro / No binario" },
            ],
            datos : {
                nombre : null, email : null, telefono : '011', genero : null, tyc : true
            }
        }
    },
    methods : {
        runGame () {

            axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

            let store = localStorage.store;
            let { datos } = this;
            let t = this;

            if (navigator.onLine) // desnegar antes de subir a produccion
            {


                t.loading = true;
                axios.post('/insertar.php', datos, {
                    headers : {
                        "Access-Control-Allow-Origin" : "*",
                        'Content-Type' : 'multipart/form-data'
                    }, 
                })
                .catch(info => 
                {
                    alert('Error interno');

                }).then( () => {
                    t.loading = false;
                    return t.$router.push({ name : 'PreviewGame' });    
                });

            } else {

                if (typeof store !== 'undefined') 
                {
                    store = JSON.parse(store);
                    console.log(store);
                } else {
                    store = [];
                }

                store.push(datos);

                console.log("datos a guardar", store);

                localStorage.store = JSON.stringify(store);

                return this.$router.push({ name : 'PreviewGame' });                

            }

        },
    }
}
</script>

<style>
    .title-img {
        width: 15em !important;
    }
    .form-content {
        border-top-right-radius: 3rem;
        border-bottom-right-radius: 3rem;
        box-shadow: 10px 10px 18px 0px rgba(128,128,128,0.75);
        -webkit-box-shadow: 10px 10px 18px 0px rgba(128,128,128,0.75);
        -moz-box-shadow: 10px 10px 18px 0px rgba(128,128,128,0.75);       
        padding: 2.5em !important;
    }
    #form {
        width: 78%;
        margin-right: 20px !important;
    }
    #form .form-control {
        border-color : #ea8215;
        border-top:  0;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
        text-indent: 10px;
        color : #565453;
    }
    .terms {
        color : #ea8215;
        border-bottom: 1px dotted #ea8215;
    }
    .btn-play {
        border-radius: 3em !important;
    }
    .f_botella {
        position: absolute;
        left: -7.1rem;
        top: -7.8rem;
        width: 11.1rem;
    }   
</style>