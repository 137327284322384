<template>
  <div>
      <div class="fig-top-left">
          <b-img :src="require('@/assets/images/franjas arriba izquierda.png')" fluid></b-img>
      </div>  
      <div class="fig-middle-left">
          <b-img :src="require('@/assets/images/ojo centrado izq.png')" fluid></b-img>
      </div>
      <div class="fig-bottom-left">
          <b-img :src="require('@/assets/images/manchas izquierda abajo.png')" fluid></b-img>
      </div>
                
      <div class="fig-bottom-center">
          <b-img :src="require('@/assets/images/logo cosecha.png')" fluid></b-img>
      </div>
      <div class="fig-bottom-right">
          <b-img :src="require('@/assets/images/pinceladas derecha inferior.png')" fluid></b-img>
      </div>                  

      <div class="fig-top-right">
          <b-img :src="require('@/assets/images/manchas arriba derecha.png')" fluid></b-img>
      </div>

      <div class="fig-middle-right">
          <b-img :src="require('@/assets/images/firuletes centrado arriba pintadas.png')" fluid></b-img>
      </div>      
  </div>
</template>

<script>
export default {
    name : "Art"
}
</script>

<style scoped>

    /* firuletes */

    .fig-top-left {
        position: fixed;
        top: -10%;
        left : -6.8%;
        width: 45%;
    }
    .fig-middle-left {
        position: fixed;
        width: 10%;   
        top: 0;
        bottom: 0;
        left: -2%;     
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;        
    }
    .fig-bottom-left {
        position: fixed;
        width: 13%;
        bottom: -5%;
        left: -2%;
    }
    .fig-bottom-center {
        position: fixed;
        margin: auto;
        left : 0;
        right: 0;
        bottom: 7%;
        text-align: center;
        width: 14%;
    }
    .fig-bottom-right {
        position: fixed;
        width: 100%;
        top: 23%;
        right: -60%;
    }
    .fig-top-right {
        position: fixed;
        width: 9%;
        top: 0;
        right: 0;
    }    
    .fig-middle-right {
        position: fixed;
        display: none;
    }
</style>