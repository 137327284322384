import { render, staticRenderFns } from "./TransaccionesList.vue?vue&type=template&id=4d373ad2&"
import script from "./TransaccionesList.vue?vue&type=script&lang=js&"
export * from "./TransaccionesList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports